const Vue = window.vue
window.eventBus = new Vue()

document.addEventListener('DOMContentLoaded', () => {
  const $el = document.getElementById('search-products')
  if ($el) {
    window.searchVue = new Vue({
      el: $el,
      data: {
        searching: false,
        searchTerm: '',
        searchTimeOut: null
      },
      beforeDestroy () {
        this.stopSearchTimeOut()
      },
      mounted () {
        window.eventBus.$on('searchFinished', () => {
          this.searching = false
        })
      },
      watch: {
        searchTerm () {
          this.onSearch()
        }
      },
      methods: {
        onSearch () {
          // Clear our timeout throughout the scroll
          this.stopSearchTimeOut()
    
          const self = this;
          // Set a timeout to run after scrolling ends
          this.searchTimeOut = setTimeout(() => {
            // Run the callback
            self.searchProducts()
          }, 400)
        },
        searchProducts () {
          if (!this.searching) {
            window.eventBus.$emit('searchStarted', this.searchTerm)
          }
        },
        stopSearchTimeOut () {
          if (this.searchTimeOut) {
            clearTimeout(this.searchTimeOut)
          }
        }
      }
    })
  }
})
